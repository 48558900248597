const routes = [
  {
    path: '/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', name: 'home', component: () => import('pages/Index.vue') },
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: { mustBeLogged: true },
    children: [
      // Dashboards
      { name: 'dashboard', path: '/dashboard', component: () => import('pages/Dashboards/Dashboard.vue') },
      { name: 'dashboardGraphs', path: '/dashboard/graphs', component: () => import('pages/Dashboards/Graphs.vue') },
      { name: 'dashboardActivity', path: '/dashboard/activity', component: () => import('pages/Dashboards/Activity.vue') },
      { name: 'bizdev', path: '/crm/bizdev', component: () => import('pages/CRM/Bizdev.vue') },

      // Ressources
      { name: 'order', path: '/commandes/:id', component: () => import('pages/Orders.vue') },

      // CMS
      { name: 'messages', path: '/messages', component: () => import('pages/Messages.vue') },
      { name: 'medias', path: '/medias/seo', component: () => import('pages/Medias/SEO.vue') },
      { name: 'addMedia', path: '/medias/ajouter', component: () => import('pages/Medias/Add.vue') },
      { name: 'exploreMedias', path: '/medias/explorer', component: () => import('pages/Medias/Explore.vue') }, // Dinguerie mais marche pas

      { name: 'ratings', path: '/ratings', component: () => import('pages/Documents/Ratings.vue') }, // WIP : replaced by documents table
      // { name: 'statistics', path: '/statistics', component: () => import('pages/Documents/Statistics.vue') }, // WIP : replaced by documents table

      // Automation
      { name: 'emails', path: '/emails', component: () => import('pages/Emails.vue') },

      // Wiki
      { name: 'wikiSubs', path: '/wiki/subs', component: () => import('pages/Wiki/Subs.vue') },
      { name: 'wikiDesign', path: '/wiki/design', component: () => import('pages/Wiki/Design.vue') },
      { name: 'wikiChangelog', path: '/wiki/changelog', component: () => import('pages/Wiki/Changelog.vue') },
      { name: 'wikiRoadmap', path: '/wiki/roadmap', component: () => import('pages/Wiki/Roadmap.vue') },

      // Dev
      { name: 'wikiS3', path: '/wiki/s3', component: () => import('pages/Dev/S3.vue') },
      { name: 'wikiRoles', path: '/wiki/roles', component: () => import('pages/Dev/Roles.vue') },
      { name: 'parsing', path: '/dev/parsing', component: () => import('pages/Dev/Parsing.vue') },
      { name: 'graphexplorer', path: '/graphexplorer', component: () => import('pages/Dev/GraphExplorer.vue') },

      // Tools
      { name: 'mailSignature', path: '/tools/signature', component: () => import('pages/Tools/Signature.vue') },

      // Kept routes
      { name: 'inbox', path: '/messagerie', component: () => import('pages/Messages/Inbox.vue') }, // V3 partially done
      { name: 'conversation', path: '/messagerie/:convId', component: () => import('pages/Messages/Conversation.vue') }, // V3 partially done

      // TODO - WIP - More generic way to handle content, assets and user listing and edition
      { name: 'contentEntries', path: '/contenu/:collection?', component: () => import('pages/Content/Entries.vue') },
      { name: 'contentEntry', path: '/contenu/:collection/:id', component: () => import('pages/Content/Entry.vue') },
      { name: 'contentDocuments', path: '/documents/:collection?', component: () => import('pages/Content/Documents.vue') },
      { name: 'contentDocument', path: '/documents/:collection/:id', component: () => import('pages/Content/Document.vue') },

      { name: 'users', path: '/users/:collection', component: () => import('pages/Users/Users.vue') },
      { name: 'user', path: '/user/:id', component: () => import('pages/Users/User.vue') },

      { name: 'assets', path: '/assets/:collection', component: () => import('pages/Assets/Assets.vue') },
      { name: 'asset', path: '/asset/:id', component: () => import('pages/Assets/Asset.vue') },

      { name: 'transactions', path: '/transactions/:collection', component: () => import('pages/Transactions/Transactions.vue') },
      { name: 'orders', path: '/commandes', component: () => import('pages/Orders/Orders.vue') },

      { name: 'automations', path: '/automations/:collection', component: () => import('pages/Automation/Automations.vue') },
      { name: 'automation', path: '/automation/:id', component: () => import('pages/Automation/Automation.vue') },

      // ########## Not used OR WIP ##########
      { name: 'settings', path: '/settings', component: () => import('pages/Settings.vue') }, // Not referenced
      { name: 'resumes', path: '/cvtheque', component: () => import('pages/Assets/Resumes.vue') }, // Not referenced
      { name: 'resume', path: '/cvtheque/:id', component: () => import('pages/Assets/Resume.vue') }, // Not referenced
      { name: 'companies', path: '/entreprises', component: () => import('pages/Assets/Companies.vue') }, // Replaced in v3
      { name: 'company', path: '/entreprise/:id', component: () => import('pages/Assets/Company.vue') }, // Replaced in v3
      { name: 'assets-types', path: '/assets/types', component: () => import('pages/AssetTypes.vue') }, // Not referenced - not working
      { name: 'custom-attributes', path: '/assets/attributes', component: () => import('pages/Assets/CustomAttributes.vue') }, // Not referenced - not working
      { name: 'custom-attribute', path: '/assets/attributes/:id', component: () => import('pages/Assets/CustomAttribute.vue') }, // Not referenced - not working
      { name: 'meetings', path: '/meetings', component: () => import('pages/Events/Meetings.vue') }, // Not referenced

      { name: 'workflows', path: '/workflows', component: () => import('pages/Automation/Workflows.vue') }, // Not referenced - Replaced in v3
      { name: 'workflow', path: '/workflow/:id', component: () => import('pages/Automation/Workflow.vue') }, // Not referenced - Replaced in v3
      { name: 'tasks', path: '/tasks', component: () => import('pages/Automation/Tasks.vue') }, // Not referenced - Replaced in v3
      { name: 'task', path: '/task/:id', component: () => import('pages/Automation/Task.vue') }, // Not referenced - Replaced in v3

      { name: 'pages', path: '/pages', component: () => import('pages/Entries/Pages.vue') }, // Replaced in v3 - still in v2 in MainLayout
      { name: 'page', path: '/page/:id', component: () => import('pages/Entries/Page.vue') }, // Replaced in v3 - still in v2 in MainLayout

      // False routes so vue-router shut up
      { name: 'emails', path: '/emails', component: () => import('pages/Entries/Emails.vue') }, // Replaced in v3 - still in v2 in MainLayout
      { name: 'email', path: '/email/:id', component: () => import('pages/Entries/Email.vue') }, // Replaced in v3 - still in v2 in MainLayout

      // { name: 'statistics', path: '/crm/stats', component: () => import('pages/CRM/Statistics.vue') },
      // { name: 'analytics', path: '/crm/analytics', component: () => import('pages/CRM/Analytics.vue') },
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
